'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

export const ScrollRestoration = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Store scroll positions keyed by "pathname + search params"
  const positions = useRef<Record<string, [number, number]>>({});
  // Track the previous path so we know what to save
  const previousPath = useRef<string | null>(null);

  // Save scroll when leaving a page
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!previousPath.current) return;
      positions.current[previousPath.current] = [
        window.scrollX,
        window.scrollY,
      ];
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Every time pathname or searchParams changes, store the old route’s position & restore the new route
  useEffect(() => {
    const currentKey = pathname + searchParams.toString();

    // If we have a previous path, store its scroll position
    if (previousPath.current) {
      positions.current[previousPath.current] = [
        window.scrollX,
        window.scrollY,
      ];
    }

    // Update previousPath so it’s set to our new route
    previousPath.current = currentKey;

    // Scroll to the stored position (or top if none stored)
    const storedPos = positions.current[currentKey];
    if (storedPos) {
      window.scrollTo(storedPos[0], storedPos[1]);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, searchParams]);

  return null;
};
