import { useCurrentApp } from '@finn/ui-utils';
import { useContext } from 'react';

import { Features } from './constants';
import { FeatureContext } from './FeatureContext';

/**
 * Returns true if the AB test value for this user is 'b'
 * @param name
 * @returns
 */
export const useIsABVariant = (name: Features) => {
  const features = useContext(FeatureContext);
  const app = useCurrentApp();

  // b2b always in A variant
  if (app === 'b2b') {
    return false;
  }

  return features[name] === 'b';
};

export const useIsCVariant = (name: Features) => {
  const features = useContext(FeatureContext);
  const app = useCurrentApp();

  // b2b always in A variant
  if (app === 'b2b') {
    return false;
  }

  return features[name] === 'c';
};

export const useGetVariant = (name: string) => {
  const features = useContext(FeatureContext);

  const app = useCurrentApp();

  // b2b always in A variant
  if (app === 'b2b') {
    return 'a';
  }

  return features[name];
};
