import { EmitEvent } from '../../types/general';
import { getGlobalObject, NavigationMethod } from '../helpers/globalObject';

const emmitAndAdaptForApp =
  (emitEvent: EmitEvent) =>
  async (
    route: string | { pathname: string },
    as: string | undefined,
    params?: { appFirst?: boolean }
  ) => {
    const url = typeof route === 'string' ? route : route.pathname;

    if (params?.appFirst) {
      delete params.appFirst;

      return [url, as, params] as Parameters<NavigationMethod>;
    }

    emitEvent({ type: 'event:navigate', value: url });

    // we give app ~1frame to start animation(30fps)
    const DELAY_FOR_APP_TO_START_ANIMATION = 33;
    await new Promise((resolve) =>
      setTimeout(resolve, DELAY_FOR_APP_TO_START_ANIMATION)
    );

    return [url, as, params] as Parameters<NavigationMethod>;
  };

const proxyHandler =
  (emitEvent: EmitEvent) =>
  async (
    target: NavigationMethod,
    thisArg: unknown,
    [route, as, params]: Parameters<NavigationMethod>
  ) => {
    const args = await emmitAndAdaptForApp(emitEvent)(route, as, params);

    return target.apply(thisArg, args);
  };

export const setupNavigation = (emitEvent: EmitEvent) => {
  const globalObject = getGlobalObject();

  const applyBindings = () => {
    if (globalObject.next) {
      globalObject.next.router.push = new Proxy(globalObject.next.router.push, {
        apply: proxyHandler(emitEvent),
      });
      globalObject.next.router.replace = new Proxy(
        globalObject.next.router.push,
        {
          apply: proxyHandler(emitEvent),
        }
      );
    }
  };

  if (globalObject?.next?.router?.push) {
    applyBindings();
  } else {
    setTimeout(applyBindings, 500);
  }
};
