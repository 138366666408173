const stickyBarHeight = 88;

export const AppStyles = () => {
  return (
    <style>{`
    html {
      -webkit-tap-highlight-color: transparent;
      overflow-x: hidden;
      max-width: calc(100vw - 2px);
    }
    body {
      -webkit-tap-highlight-color: transparent;
    }
    body.pdp.region-de-DE {
      margin-top: -24px;
    }
    body.checkout {
      max-width: 100%;
      overflow-x: hidden;
    }

    div[data-appid='product-details'] {
      padding-bottom: calc(${stickyBarHeight}px + var(--bottom-inset) + 16px);
      padding-top: 24px;
  
      width: 100%;
      height: 100%;
      margin: 0 auto;
      max-width: calc(100vw - 32px);
    }

    /* 
      Web putting sticky bars to the top, which
      does not really work for app, so we move it back to bottom
    */
    [data-appid='product-details'] [data-appid='sticky-bar'] {
      bottom: 0;
      height:${stickyBarHeight}px !important;
      top: calc(
        var(--webview-height) - ${stickyBarHeight}px - var(--bottom-inset) !important;
      );
      transform: translate(0px, 0px);
    }

    body:not(.home) [role='dialog'] button:has(svg .menu-close-icon),
    body:not(.home) [role='dialog'] button:has(svg[aria-label='close-icon']):not(.bottom-sheet),
    body:not(.home) [data-appid='image-gallery-mobile-close-button'],
    .my_cars [data-testid='header'] {
      visibility: hidden;
    }

    .my_car [data-appid='heading'] div span {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    div[data-appid='image-gallery-mobile-fullScreen'] {
      top: calc((${stickyBarHeight}px + var(--bottom-inset)) * -1);
    }
  
    div[data-appid='emission-modal-content'] {
      padding-top: 48px;
      margin-top: calc((${stickyBarHeight}px + var(--bottom-inset)) * -1);
    }
  
    div[data-appid='bottom-marker'] {
      height: 1px;
    }
  
    .order_status [data-cy='steps-module'], 
    .thank_you [data-cy='steps-module'],
    .my_cars [data-appid='mycars'],
    .report_damage [data-appid='info-modal-header'] + div {
      margin-top: 16px;
    }
  
    .my_cars [data-appid='mycars'] h1,
    .my_cars [data-appid='service-selector-dialog-header'],
    .my_cars [data-appid='retention-modal-dialog-header'],
    .my_car [data-appid='service-selector-dialog-header'],
    .my_car [data-appid='retention-modal-dialog-header'],
    .report_damage [role='dialog'] button,
    .report_damage [data-appid='info-modal-header'] {
      display: none;
    }
  
    .my_cars [role='dialog'],
    .my_car [role='dialog'] {
      padding-top: 32px;
    }
  
    .my_cars [data-appid='service-selector-dialog-header'] + div,
    .my_cars [data-appid='retention-modal-dialog-header'] + div,
    .my_car [data-appid='service-selector-dialog-header'] + div,
    .my_car [data-appid='retention-modal-dialog-header'] + div {
      margin-top: 32px;
    }

    .my_car [data-appid='spacing_wrapper'] {
      margin-top: 0px;
    }
  
    .my_car [data-appid='heading'] {
      margin-bottom: 0px;
    }
  
    .schedule_service [data-appid='schedule-service-container'],
    .report_damage [data-appid='report-damage-container'],
    .return [data-appid='return-container'] {
      margin-bottom: 16px;
    }
  }
  `}</style>
  );
};
