'use client';

import { run } from '@finn/ua-app/sdk';
import { EXCLUDED_PATH_FOR_COOKIE_BANNER } from '@finn/ua-constants';
import {
  config,
  isMobileApp,
  isServer,
  renderSegmentSnippet,
} from '@finn/ui-utils';
import startsWith from 'lodash/startsWith';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { Suspense } from 'react';

// For now we need to keep this component as client
// because we import from ui-utils that causes error with SSR

const UserCentricsScript = () => {
  const searchParams = useSearchParams();
  const isSkipUsercentric = searchParams.get('skipusercentrics');

  if (isSkipUsercentric) {
    return null;
  }

  return (
    <Script
      id="usercentrics-cmp"
      strategy="lazyOnload"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      data-settings-id={config.USERCENTRICS_ID}
    />
  );
};

export const ExternalScripts = () => {
  const pathname = usePathname();
  const suppressCookieBanner = EXCLUDED_PATH_FOR_COOKIE_BANNER.some((path) =>
    startsWith(pathname, path)
  );

  const initDD = () => {
    if (!config.DATADOG_CLIENT_TOKEN) return;

    const botPattern =
      '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon)';

    const regex = new RegExp(botPattern, 'i');
    const isBot = regex.test(window.navigator.userAgent);

    const sessionSampleRate = isBot ? 0 : 10;
    const sessionReplaySampleRate = isBot ? 0 : 5;
    (window as any).DD_RUM.init({
      clientToken: config.DATADOG_CLIENT_TOKEN,
      applicationId: config.DATADOG_APPLICATION_ID,
      site: 'datadoghq.eu',
      service: 'finn.com',
      env: config.DEPLOY_URL,
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  };

  return (
    <>
      <Script
        src="https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js"
        async
        strategy="lazyOnload"
        onLoad={initDD}
      />
      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
      />
      {!isServer() && !window?.analytics && (
        <Script
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: renderSegmentSnippet(),
          }}
        />
      )}
      {/*
          We supress cookie banner for mobile app
          https://docs.usercentrics.com/#/cmp-v2-ui-api?id=suppress-the-cmp
        */}
      {suppressCookieBanner || isMobileApp() ? (
        <Script>{`var UC_UI_SUPPRESS_CMP_DISPLAY=true;`}</Script>
      ) : null}
      <Suspense>
        <UserCentricsScript />
      </Suspense>
    </>
  );
};

run();
