'use client';

import { FullStory } from '@finn/auto-ui/components/FullStory/FullStory';
import { GlobalModals } from '@finn/auto-ui/components/GlobalModals/GlobalModals';
import { ClosedUserGroupProvider } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { ToastManager } from '@finn/design-system/atoms/toast';
import { MobileAppBindings } from '@finn/ua-app/layouts/MobileAppRootLayout';
import { GoogleOneTap, UserAccountProvider } from '@finn/ua-auth';
import { FeatureProvider } from '@finn/ua-featureflags';
import { DateInfoProvider } from '@finn/ui-components';
import {
  AppProvider,
  NavigationMenuProvider,
  SessionContext,
} from '@finn/ui-modules';
import { isMobileApp, useSession } from '@finn/ui-utils';
import { usePathname } from 'next/navigation';
import { SessionProvider } from 'next-auth/react';
import { ReactNode } from 'react';

import filterBrandsModelsData from '../../localization/available.filters.json';
import allFeatures from '../../localization/features2.json';

// TODO long term we want to kill this component
// to have better SSR

type CoreClientProvidersProps = {
  children: ReactNode;
  locale?: string;
};

export const CoreClientProviders = ({ children }: CoreClientProvidersProps) => {
  const pathname = usePathname();
  const withNewAppBindings =
    (!pathname?.includes('/mobile') ||
      pathname?.includes('/register') ||
      pathname?.includes('/authv2')) &&
    isMobileApp();

  return (
    <SessionProvider refetchOnWindowFocus={false}>
      <SessionContext.Provider value={useSession}>
        <UserAccountProvider>
          <NavigationMenuProvider brandModels={filterBrandsModelsData}>
            <FeatureProvider allFeatures={allFeatures}>
              <FullStory />
              <AppProvider>
                <DateInfoProvider>
                  <ClosedUserGroupProvider>
                    <GoogleOneTap />
                    {children}
                    <GlobalModals />
                    {withNewAppBindings && <MobileAppBindings />}
                  </ClosedUserGroupProvider>
                </DateInfoProvider>
              </AppProvider>
            </FeatureProvider>
            <ToastManager />
          </NavigationMenuProvider>
        </UserAccountProvider>
      </SessionContext.Provider>
    </SessionProvider>
  );
};
