import { compareVersions } from 'compare-versions';

import { CB, EmitEvent } from '../../types/general';
import { setupWebRouting } from '../deprecated/routing';
import { setupAnalytics } from './analytics';
import { setupAuth } from './auth';
import { getCurrentPageType } from './constants';
import {
  PageHandlersWhenComplete,
  PageHandlersWhenInteractive,
} from './handlers';
import { setupLogging } from './logging';
import { setupNavigation } from './navigation';
import { setupScrollListener } from './scroll';
import { setupStorage } from './storage';
import { injectStyles } from './styles';

type ConnectorProps = {
  screenName: string;
  locale: string;
  bottom: string;
  version: string;
  allowSamePageWebNavigation?: boolean;
  withWebPDPColorChange?: boolean;
  localState?: string;
  emitEvent: EmitEvent;
  isNativeNavigationFirst?: boolean;
  addListener: (name: string, cb: CB) => () => void;
};

const isReady = () => Boolean(document?.readyState === 'complete');

const setupConnectors = ({
  screenName,
  allowSamePageWebNavigation,
  isNativeNavigationFirst,
  withWebPDPColorChange,
  locale,
  version,
  emitEvent,
}: ConnectorProps) => {
  const pageType = getCurrentPageType(screenName);

  document.documentElement.classList.add('native-app-screen');
  document.body?.classList?.add(pageType, `region-${locale}`);

  setupAuth(emitEvent);
  setupAnalytics(emitEvent);
  setupScrollListener(emitEvent);

  // TODO setupWebRouting is deprecated, feel free to delete condition and deprecated code after 1.55.0 release
  if (isNativeNavigationFirst) {
    setupWebRouting(emitEvent, {
      screenName,
      allowSamePageWebNavigation,
      withWebPDPColorChange,
      version,
    });
  } else {
    setupNavigation(emitEvent);
  }

  PageHandlersWhenInteractive[pageType]?.();
  PageHandlersWhenComplete[pageType]?.();

  emitEvent({ type: 'ready' });
};

export const connectWebToApp = (props: ConnectorProps) => {
  const handleDocumentReadyStateChange = () => connectWebToApp(props);

  const isNativeNavigationFirst = compareVersions(props.version, '1.47.0') < 0;

  // we need to setup storage earlier
  setupStorage(props.emitEvent, props.localState);
  injectStyles({ isNativeNavigationFirst, bottom: props.bottom || '0' });

  if (isReady()) {
    setupLogging(props.emitEvent);
    setupConnectors({ ...props, isNativeNavigationFirst });
  } else {
    document.addEventListener(
      'readystatechange',
      handleDocumentReadyStateChange
    );
  }

  return () => {
    document.removeEventListener(
      'readystatechange',
      handleDocumentReadyStateChange
    );
  };
};
