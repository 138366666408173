'use client';

import { useProcessVoucherParam } from '@finn/auto-ui/hooks/useProcessVoucherParam';
import { useReferralTracker } from '@finn/auto-ui/hooks/useReferralTracker';
import { useSegmentLoader } from '@finn/auto-ui/hooks/useSegmentLoader';
import { useSetTestCookieForDev } from '@finn/auto-ui/hooks/useSetTestCookieForDev';
import { useSetSessionId } from '@finn/auto-ui/modules/api/hooks/useSetSessionId';
import {
  CookieKeys,
  DefaultLocale,
  formatUtmSourceValue,
  getClientCookie,
  isCookieExist,
  loadCurrentLocale,
  Locale,
  pageEvent,
  setClientCookie,
  setCurrentLang,
  setLocaleCookie,
  setMessages,
  useLocaleChanger,
} from '@finn/ui-utils';
import { usePathname } from 'next/navigation';
import queryString from 'query-string';
import { Suspense, useEffect, useMemo } from 'react';

import localesConfig from '../../locales.json';
import { ScrollRestoration } from './ScrollRestoration';

// this component render nothing, it goal just to run hooks in SSR friendly way

// we filter out dev errors that we can ingore
if (process.env.NODE_ENV === 'development') {
  const original = console.error;
  const ERRORS_TO_SKIP = [
    // thise error generated by segment snippet
    // which tries to set cookie on finn.com even on localhost
    "Can't write cookie on domain *.www.finn.com",
    'There was an error setting cookie `_pk_testcookie_domain`. Please check domain and path.',
  ];

  // we filter out dev errors that we can ingore
  console.error = (...args) => {
    if (ERRORS_TO_SKIP?.includes(args[0])) {
      return;
    }

    original(...args);
  };
}

export const SetupSideEffects = ({ locale }: { locale: string }) => {
  const pathname = usePathname();
  const onChangeLocale = useLocaleChanger();
  const { messages } = useMemo(() => loadCurrentLocale(locale), [locale]);

  // set global localization data
  setCurrentLang(locale);
  setMessages(messages);

  /*
    Very dirty hack to try to overcome netlify bug with locales
    TODO delete me please https://github.com/netlify/netlify-plugin-nextjs/issues/788
  */
  useEffect(() => {
    const localeFromUrl = window.location.pathname?.split('/')?.[1];
    const isSupportedLocale =
      localesConfig.supportedLocales?.includes(localeFromUrl);
    if (pathname !== '/' || isSupportedLocale) {
      if (isSupportedLocale) {
        setLocaleCookie(localeFromUrl as Locale);
      } else {
        setLocaleCookie(DefaultLocale);
      }
    }

    const localeInCookie = getClientCookie(CookieKeys.NEXT_LOCALE);
    if (
      localeInCookie === Locale.ENGLISH_USA &&
      localeFromUrl !== Locale.ENGLISH_USA
    ) {
      onChangeLocale(localeInCookie);
    }
  }, [locale, pathname, onChangeLocale]);

  // Read b2b-multiplier from url and store in cookie
  useEffect(() => {
    const parsedURLQuery = queryString.parse(window.location.search);
    const b2bMultiplierValue = parsedURLQuery['b2b-multiplier'];

    if (b2bMultiplierValue) {
      setClientCookie(CookieKeys.B2B_MULTIPLIER, b2bMultiplierValue, 30);
    }
  }, []);

  // Read utm_source from url and store in cookie
  // Marketing cookies are saved based on first origin that customer came from
  // and they don't overridden when customer comes after from a different place/marketing campaign.
  useEffect(() => {
    const parsedURLQuery = queryString.parse(window.location.search);
    let utmSourceValue = parsedURLQuery.utm_source;
    if (Array.isArray(utmSourceValue)) {
      utmSourceValue = utmSourceValue[0];
    }

    const isUTMCookieExist = isCookieExist(CookieKeys.UTM_SOURCE);

    if (utmSourceValue && !isUTMCookieExist) {
      const formattedSourceValue = formatUtmSourceValue(utmSourceValue);
      setClientCookie(CookieKeys.UTM_SOURCE, formattedSourceValue, 30);
    }
  }, []);

  useSetSessionId();

  // Track (Page Load/History change) with Segment
  useEffect(() => {
    pageEvent(pathname);
  }, [pathname]);

  useSegmentLoader();
  useReferralTracker();
  useSetTestCookieForDev();
  useProcessVoucherParam();

  // a bunch of side effects related to cookies and analytics
  useEffect(() => {
    // we check UC_Integrations and notify segment about what can be tracked
    if (window.UC_Integrations && window.dataLayer) {
      const ucAnalyticsService = 'Google Analytics';
      const ucAdService = 'Adwords Remarketing Lists';
      const ucAdWords = 'AdWords';
      window.dataLayer.push([
        'consent',
        'update',
        {
          ad_storage:
            window.UC_Integrations[ucAdService] ||
            window.UC_Integrations[ucAdWords]
              ? 'granted'
              : 'denied',
          analytics_storage: window.UC_Integrations[ucAnalyticsService]
            ? 'granted'
            : 'denied',
        },
      ]);
    }
    window.dispatchEvent(new Event('afterSegmentLoad'));
  }, []);

  return (
    <Suspense>
      <ScrollRestoration />
    </Suspense>
  );
};
