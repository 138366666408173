import {
  config,
  isAllConsentTrue,
  UCConsentEvent,
  updateSegmentIntegration,
} from '@finn/ui-utils';
import { useEffect } from 'react';

const fallbackSegmentLoaderLocalhost = () => {
  // afterConsent event is not triggered on localhost:3001 implicitly.
  // A workaround to always load analytics for localhost
  if (window.location.hostname === 'localhost') {
    window.UC_Integrations = { 'Segment.io': true, 'Facebook Pixel': true };
    if (!window.analytics) {
      console.warn('window.analytics is not defined. try refreshing the page');
    }
    window.analytics?.load?.(config.ANALYTICS_WRITE_KEY, {
      integrations: window.UC_Integrations,
    });
    window.dispatchEvent(new Event('afterSegmentLoad'));
  }
};

export const useSegmentLoader = () => {
  useEffect(() => {
    fallbackSegmentLoaderLocalhost();
    // this event `afterConsent` needs to be added on usercentrics dashboard
    window.addEventListener('afterConsent', (consent: UCConsentEvent) => {
      const detail = { ...consent.detail };
      if (detail?.type === 'explicit' || isAllConsentTrue(detail)) {
        delete detail.event;
        delete detail.type;
        delete detail.action;
        updateSegmentIntegration(detail);
        window.analytics?.load?.(config.ANALYTICS_WRITE_KEY, {
          integrations: window.UC_Integrations,
        });
        window.dispatchEvent(new Event('afterSegmentLoad'));
      }
    });
  }, []);
};
